import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VideoPractice from './pages/videopractice';
import Profile from './pages/profile';
import Homepage from './pages/homepage';
import Howtouse from './pages/howtouse';
import Registry from './pages/registry';
import LoginForm from './components/LoginForm/LoginForm';
import Registryverification from './pages/registryverification';
import Validationerror from './pages/validationerror';
import Changepwd from './pages/changepwd';

function App() {
  const { email, verified } = useSelector((state) => state.userSlice);

  return (
    <Routes>
      {/* public */}
      <Route path="/" element={<Homepage />} />
      <Route path="/homepage" element={<Homepage />} />
      <Route path="/howtouse" element={<Howtouse />} />


      <Route path="/loginform" element={<RedirectIfAuthorized><LoginForm /></RedirectIfAuthorized>} />
      <Route path="/registerform" element={<RedirectIfAuthorized><Registry /></RedirectIfAuthorized>} />


      {/* authorized */}
      <Route path="/captions" element={<ProtectedRoute><VideoPractice /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/cpwd" element={<ProtectedRoute><Changepwd /></ProtectedRoute>} />
      {email && !verified ? <Route path="/verification" element={<Registryverification />} /> : ''}
      {email && !verified ? <Route path="/validationerror" element={<Validationerror />} /> : ''}
    </Routes>
  );
}

const RedirectIfAuthorized = ({ children }) => {
  const { email, verified } = useSelector((state) => state.userSlice);

  if (email) {
    return <Navigate to="/profile" />;
  }

  return children;
};

// ��������� ��� �������� ����������� ������������ � ��������������� ��� �������������
const ProtectedRoute = ({ children }) => {
  const { email, verified } = useSelector((state) => state.userSlice);

  if (email && !verified) {
    // ��������������� �� �������� �����������, ���� ����������� �� ��������
    return <Navigate to="/verification" />;
  }

  // ����������� ����������� ��������, ���� ����������� �������� ��� ������������ �� �����������
  return children;
};

export default App;
