import React from 'react'
import styles from './modal.module.css'



function Modal({active, setActive, children}){

    return (
        <diaglog className={active ? `${styles.modal} ${styles.active}` : styles.modal} onClick={()=>{ setActive(false) }}>
            <div className={styles.content} onClick={e => e.stopPropagation()} >
                <button class={styles.closeBtn} onClick={()=>{ setActive(false) }}>&#10060;</button>
                {children}
            </div>
        </diaglog>
    )
}


export default Modal