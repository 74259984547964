import Pointer from '../Pointer/Pointer'
import Subtitle from '../Subtitle/Subtitle'
import styles from "./timeline.module.css"
import {React} from 'react'
import Loading from '../../../ui/Loading/Loading' 
import { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedSubtitle} from '../../../../features/subtitles/subtitleSlice'


function TimeLine({ratio}){
    const dispatch = useDispatch()
    const {subtitles, selectedSubtitle} = useSelector(state=> state.subtitle)
    const {isPlayerLoaded, playerRef:player, isPlaying} = useSelector(state=> state.player)


    let [userActivity, setUserActivity] = useState(false)
    let [timeLineScroll, setTimeLineScroll] = useState()
    let timeLineRef = useRef()


    useEffect(()=> {
        if (!userActivity) {
            timeLineRef.current.scrollLeft = timeLineScroll - 150}
            timeLineRef.current.onwheel = onScrollTimeline}, [ timeLineScroll])

    function getRelativeCoordsByEvent(e, container){
        let rect = container.getBoundingClientRect();
        let x = e.clientX + container.scrollLeft - rect.left;
        let y = e.clientY + container.scrollTop - rect.top;
        return {x: x, y: y};
    
    }

    function handleOnClick(e){
        if (e.target.id != "sub"){
            let timePos = getRelativeCoordsByEvent(e, e.target).x
            setTimeLineScroll(timePos)
            player.current.internalPlayer.seekTo(timePos / ratio)
        }
    }

    function getSubIndexBySubId(sub_id){
        return subtitles.findIndex(e=> e.sub_id == sub_id)
    }

    function onScrollTimeline(event){
        event.preventDefault()
        event.target.scrollLeft += event.deltaY
    }
    // timeLineRef.addEventListener(("wheel", onScrollTimeline, { passive: false }))

    if (isPlayerLoaded){
        return (
            <div
                ref={timeLineRef}            
                className={styles.timeline} 
                onClick={ handleOnClick}
                onMouseLeave={()=>{setUserActivity(false)}} 
                onMouseEnter={()=>{setUserActivity(true)}} 
                >

                {subtitles.map((e, index)=> <Subtitle
                                                similarity={window.stringSimilarity.compareTwoStrings(e.text || " ", e.userText|| " ")}
                                                key={e.sub_id}
                                                player={player}
                                                getSubIndex={getSubIndexBySubId}
                                                selectedSubtitle={selectedSubtitle}
                                                data={e}
                                                ratio={ratio}
                                                start={+e.start} 
                                                duration={+e.duration} />)}

                <Pointer setTimeLineScroll={setTimeLineScroll} userActivity={userActivity} isPlaying={isPlaying} player={player} ratio={ratio} />
            </div>
        )
        }

    else {
        return <Loading />
    }
}

export default TimeLine;