import { loginUser, setLoading } from "../../features/user/userSlice";
import { useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const LoginPage = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({ email: '', password: '' });
    const emailRef = useRef();
    const pwdRef = useRef();


    useEffect(() => {
        if (emailRef.current && pwdRef.current) {
            setData({ email: emailRef.current.value, password: pwdRef.current.value });
        }
    }, [emailRef.current, pwdRef.current]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoading(true))
        dispatch(loginUser(data));
    };

    const handleEmailChange = (e) => {
        setData({ ...data, email: e.target.value });
    };

    const handlePasswordChange = (e) => {
        setData({ ...data, password: e.target.value });
    };

    return (
        <div className="bg-white dark:bg-gray-900">
            <div className="flex justify-center h-screen">
                <div className="hidden bg-cover lg:block lg:w-2/3" style={{ backgroundImage: "url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)" }}>
                    <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                        <div>
                            <h2 className="text-2xl font-bold text-white sm:text-3xl">Welcome to Listen and Recognize</h2>
                            <p className="max-w-xl mt-3 text-gray-300">
                                Your gateway to language learning! Our platform is dedicated to mastering all languages through the use of YouTube videos with subtitles in the original language. The essence of our application lies in the user's ability to listen to sentences from videos in a foreign language and try to fill in the blanks with words from that sentence, arranged in a random order. Additionally, we offer a more advanced approach where users can input the words instead of using puzzles. Join us and embark on your journey to language fluency!
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                    <div className="flex-1">
                        <div className="text-center">
                            <div className="flex justify-center mx-auto">
                                <img className="w-auto h-7 sm:h-8" src="/static/img/logo.png" alt="" />
                            </div>
                            <p className="mt-3 text-gray-500 dark:text-gray-300">Login to access your account</p>
                        </div>

                        <div className="mt-8">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                                    <input ref={emailRef} type="email" id="email" placeholder="example@example.com" onChange={handleEmailChange} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                </div>

                                <div className="mt-6">
                                    <div className="flex justify-between mb-2">
                                        <label htmlFor="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                        <a href="#" className="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">Forgot password?</a>
                                    </div>
                                    <input ref={pwdRef} type="password" id="password" placeholder="Your Password" onChange={handlePasswordChange} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                </div>

                                <div className="mt-6">
                                    <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">Login</button>
                                </div>
                            </form>

                            <p className="mt-6 text-sm text-center text-gray-400">Don't have an account yet? <Link to="/registerform" className="text-blue-500 focus:outline-none focus:underline hover:underline">Create account</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
