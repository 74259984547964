import { configureStore } from '@reduxjs/toolkit';

import subtitleReducer from '../features/subtitles/subtitleSlice'
import playerReducer from "../features/player/playerSlice"
import userReducer from '../features/user/userSlice';


export default configureStore({
    reducer: {
        subtitle: subtitleReducer,
        userSlice: userReducer,
        player: playerReducer,
    },
});