import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSubtitle } from '../../../../features/subtitles/subtitleSlice';
import styles from "./subtitle.module.css";

function areEqual(prevProps, nextProps) {
    if (prevProps.data != nextProps.data) { return false }
    if (nextProps.selectedSubtitle == nextProps.getSubIndex(nextProps.data.sub_id)) {
        return false
    }
    else if (prevProps.selectedSubtitle == prevProps.getSubIndex(prevProps.data.sub_id) && prevProps.selectedSubtitle != nextProps.selectedSubtitle) {
        return false
    }
    else {
        return true
    }
}

let Subtitle = ({ player, getSubIndex, selectedSubtitle, data, ratio, start, duration, similarity }) => {

    const dispatch = useDispatch()



    function onSubClick(e) {
        dispatch(setSelectedSubtitle(getSubIndex(data.sub_id)))
    }

    function onSubDoubleClick() {
        player.current.internalPlayer.seekTo(+start)

    }


    return (
        <div
            id={"sub"}
            subId={data.sub_id}
            onDoubleClick={onSubDoubleClick}
            onClick={(e) => { onSubClick(e) }}
            style={{
                boxShadow: `0px 0px 11px 1px rgb(${255 - (similarity * 255)}, ${similarity * 255}, 0) inset`,
                width: (+duration * ratio * 0.95) + "px",
                height: 50 + 'px',
                left: (+start * ratio) + 'px',

            }} className={selectedSubtitle == data.sub_id ? styles.subtitle + " " + styles.selectedSubtitle : styles.subtitle}>

        </div>
    )
}

export default React.memo(Subtitle, areEqual);