import { React } from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { fetchSubtitles } from '../../../features/subtitles/subtitleSlice';
import { initPlayer, setIsPlaying, setVideoId, unmountPlayer } from "../../../features/player/playerSlice"

import YouTube from 'react-youtube'

import Timeline from '../timeline/Timeline/Timeline.jsx';
import PlayerControls from './PlayerControl/PlayerControls';
import Loading from "../../ui/Loading/Loading";
import SubtitlePanel from './SubtitlePanel/SubtitlePanel';




const ratio = 20

function Player() {
    const dispatch = useDispatch()

    const subtitles = useSelector(state => state.subtitle.subtitles)
    let { lastVideo } = useSelector(state => state.userSlice)



    return (
        <div className="p-1">
            <div className='flex md:flex-row flex-nowrap gap-3 flex-col'>
                <YouTube
                    className='  md:basis-full'
                    opts={{
                        width: window.innerWidth < 768 ? window.innerWidth : window.innerWidth / 2,
                        height: window.innerWidth < 768 ? window.innerWidth * 9 / 16 : (window.innerWidth / 2) * 9 / 16
                    }}
                    onReady={(e) => {
                        dispatch(initPlayer({ current: { internalPlayer: e.target } }))
                        dispatch(fetchSubtitles(lastVideo, e.target.getVideoData().title))

                    }}
                    onPlay={() => { dispatch(setIsPlaying(true)) }}
                    onPause={() => { dispatch(setIsPlaying(false)) }}
                    videoId={lastVideo} />



                <SubtitlePanel
                    className='basis-1/2 max-md:basis-full'
                />

            </div>

            <div className='flex flex-row gap-1'>
                <PlayerControls className="basis-1/2 max-md:basis-full" />
                <div className='basis-1/2 max-md:hidden '> </div>

            </div>

            <div className="">
                {subtitles.length !== 0 ?
                    <Timeline ratio={ratio} /> : <Loading />}
            </div>


        </div>

    )
}

export default Player;