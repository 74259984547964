import React from 'react'
import PlayButton from '../PlayerButton/PlayButton'
import Volume from '../Volume/Volume'
import Loading from '../../../ui/Modal/Modal'
import PlaybackSpeed from '../PlayerSpeed/PlaybackSpeed'
import RepeatButton from '../RepeatButton/RepeatButton'


import { useSelector, useDispatch } from 'react-redux'


function PlayerControls() {
    const { isPlayerLoaded } = useSelector(state => state.player)


    if (isPlayerLoaded) {
        return (

            <div className='flex max-md:w-full max-h-18 flex-row justify-between m-2 p-2 bg-gray-100 rounded-md border border-gray-200' >
                <PlayButton />
                <Volume />
                <PlaybackSpeed />
                <RepeatButton />
            </div >

        )
    }
    else {
        return (
            <Loading />
        )
    }

}

export default PlayerControls