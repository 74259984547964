import RegisterForm from "../components/RegisterForm/RegisterForm"


export default () => {
    return (
        <div className="p=15">
            <RegisterForm>
            </RegisterForm>
        </div>
    )
}