import {setSelectedWordText, setUserWords, setSelectedWordId, setUserTextInSelectedSubtitle} from '../../../../../../../features/subtitles/subtitleSlice'
import { useSelector, useDispatch } from 'react-redux'



function Menu({word, index}){
    let dispatch = useDispatch()

    return (
        <div style={{
            margin: "0",
            position: "absolute",
            top: '100%',
            left: '0px',
            backgroundColor: "aqua",
            }}>
                <button onClick={()=> dispatch(setSelectedWordId(index))} >Edit Word</button>
        </div>
    )

}



export default Menu