import { useSelector, useDispatch } from "react-redux"
import { setRepeatSubtitle } from "../../../../features/player/playerSlice"
import IcoToogle from "../../../ui/IcoButton/IcoToogle"

function RepeatButton() {
    let dispatch = useDispatch()
    const svgPath = 'M12.872 13.191h5.128v-5.127c-.008-1.135-.671-1.408-1.473-.605l-1.154 1.158c-1.015-.795-2.257-1.23-3.566-1.23-1.55 0-3.009.604-4.104 1.701-1.099 1.092-1.703 2.553-1.703 4.103 0 1.553.604 3.012 1.701 4.107 1.097 1.097 2.555 1.702 4.106 1.702 1.55 0 3.009-.605 4.106-1.703.296-.297.558-.621.78-.965.347-.541.19-1.26-.35-1.605-.539-.346-1.258-.189-1.604.35-.133.207-.292.4-.468.58-.659.658-1.534 1.02-2.464 1.02-.93 0-1.805-.361-2.464-1.02-.657-.658-1.02-1.533-1.02-2.465 0-.93.362-1.805 1.02-2.461.659-.658 1.534-1.021 2.464-1.021.688 0 1.346.201 1.909.572l-1.448 1.451c-.803.802-.53 1.458.604 1.458z'
    const { player } = useSelector(state => state.player)
    const { subtitles,
        selectedSubtitle,
        selectedWord,
        splitedSubtitle,
        userWords,
        shuffledSubtitle } = useSelector(state => state.subtitle)

    return (

        <div className='flex items-center m-1' >
            <IcoToogle action={
                setRepeatSubtitle}
                onPath={svgPath} offPath={svgPath}  ></IcoToogle>
        </div >
    )
}


export default RepeatButton