import { createSlice } from '@reduxjs/toolkit'



let defaultVideoId = '9E1SLTvngEw'
export const playerSlice = createSlice({
    name: "player",
    initialState: {
        defaultVideoId: '9E1SLTvngEw',
        isPlayerLoaded: false,
        isPlaying: false,
        videoId: defaultVideoId,
        playerRef: 0,
        isRepeat: true,


    },

    reducers: {
        initPlayer: (state, action) => {
            state.isPlayerLoaded = true
            state.playerRef = action.payload
        },

        unmountPlayer: (state, action) => {
            state.isPlayerLoaded = false
            state.playerRef = 0
        },

        setIsPLayerLoaded: (state, action) => {
            state.isPlayerLoaded = action.payload
        },

        setIsPlaying: (state, action) => {
            state.isPlaying = action.payload
        },

        setVideoId: (state, action) => {
            state.videoId = action.payload
        },


        setRepeatSubtitle: (state, action) => {
            console.log(action.payload);
            if (state.isPlayerLoaded) {
                state.isRepeat = !action.payload
            }
        },
    }
})

export const { initPlayer, setIsPLayerLoaded, setIsPlaying, setVideoId, setRepeatSubtitle, unmountPlayer } = playerSlice.actions

export default playerSlice.reducer