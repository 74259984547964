import React from 'react'
import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setLevel } from "../../../../features/subtitles/subtitleSlice"
import SubtitleEditorEasy from './UserSubtitleEditor/EasyLevelUSE'
import SubtitleEditorHard from './UserSubtitleEditor/HardLevelUSE'

import Loading from '../../../ui/Modal/Modal'

const subtitleAmount = 6

function SubtitlePanel() {
    const dispatch = useDispatch()

    const { isPlayerLoaded, videoId } = useSelector(state => state.player)
    const { subtitles, selectedSubtitle, level } = useSelector(state => state.subtitle)




    let subtitleInputRef = useRef()
    let subtitleList = (selectedSubtitle >= subtitleAmount) ?
        subtitles.slice(selectedSubtitle - subtitleAmount, selectedSubtitle)
        :
        subtitles.slice(0, selectedSubtitle)


    if (isPlayerLoaded && subtitles[selectedSubtitle]) {
        return (
            <div className="w-full h-auto flex flex-col justify-between" value>
                <form onChange={(e) => dispatch(setLevel(e.target.value))} className="flex justify-center items-center space-x-4">
                    <label className={`relative flex items-center text-white cursor-pointer ${level === "2" ? 'bg-purple-500' : 'bg-gray-500'} rounded-md p-2`}>
                        <input type="radio" id='easy' value="2" name="level" className="sr-only" />
                        <span className="text-sm">Puzzle</span>
                    </label>
                    <label className={`relative flex items-center text-white cursor-pointer ${level === "1" ? 'bg-purple-500' : 'bg-gray-500'} rounded-md p-2`}>
                        <input type='radio' id='hard' value="1" name="level" className="sr-only" />
                        <span className="text-sm">Type</span>
                    </label>
                </form>




                <div className="flex h-auto flex-wrap-reverse flex-col ">
                    <div className='  md:p-4 bg-slate-400 rounded-sm border border-slate-700 m-1'>{subtitleList.map((e) => e.userText ? <p>{e.userText}</p> : <p>-</p>)}</div>

                    <div className=' rounded-sm border'>
                        {level == "2" ? (<SubtitleEditorEasy subtitleInputRef={subtitleInputRef} />) : null}
                        {level == '1' ? (<SubtitleEditorHard subtitleInputRef={subtitleInputRef} />) : null}
                    </div  >
                </div>
            </div>
        )
    }
    else { return <Loading /> }
}



export default React.memo(SubtitlePanel)