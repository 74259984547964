"use client";
import React from "react";
import { useSelector } from "react-redux";

function LoadingModal() {
    const message = "Loading..."
    const { loading } = useSelector(state => state.userSlice)
    if (!loading) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <p className="text-lg font-roboto text-[#121212]">{message}</p>
                <div className="flex justify-center mt-4">
                    <svg
                        className="animate-spin h-8 w-8 text-blue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
            </div>
        </div>
    );
}


export default LoadingModal;