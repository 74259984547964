export default ({ svgPath }) => {
    return (
        <>
            <div className="sm:hidden">
                {/* ������ ��� ������� xs */}
                <button className="block px-2.5 py-2.5 text-indigo-600 duration-150 bg-indigo-50 rounded-lg hover:bg-indigo-100 active:bg-indigo-200">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d={svgPath} clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="hidden sm:block md:hidden">
                {/* ������ ��� ������� sm */}
                <button className="block px-3 py-3 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d={svgPath} clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="hidden md:block lg:hidden">
                {/* ������ ��� ������� md */}
                <button className="block px-3.5 py-3.5 text-indigo-600 duration-150 bg-indigo-50 rounded-lg hover:bg-indigo-100 active:bg-indigo-200">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d={svgPath} clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="hidden lg:block xl:hidden">
                {/* ������ ��� ������� lg */}
                <button className="block px-4 py-4 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d={svgPath} clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="hidden xl:block">
                {/* ������ ��� ������� xl � ���� */}
                <button className="block px-5 py-5 text-indigo-600 duration-150 bg-indigo-50 rounded-lg hover:bg-indigo-100 active:bg-indigo-200">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                        <path fillRule="evenodd" d={svgPath} clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </>
    )
}
