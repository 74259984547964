import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Col } from 'react-bootstrap';
// import stringSimilarity from 'string-similarity'

function subtytleNormalizer(subtitles) {

}

const fetchValidSubtitles = createAsyncThunk('subtitles/fetchValidSubtitles', async (vid) => {
    const response = await fetch('/validsub/' + vid);
    const data = await response.json();
    return data;
});


function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// param {videoId: }
function saveUserTextToDataBase(state, action) {
    console.log(action.payload)
    let saveSubResponse = fetch("/aus", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            video_id: action.payload.lastVideo,
            FK_subtitle_id: state.subtitles[state.selectedSubtitle].id,
            user_text: state.subtitles[state.selectedSubtitle].userText,
            progress: action.payload.progress
        })
    });
}


function selectSubtitle(state, id) {
    state.selectedSubtitle = id
    state.splitedSubtitle = state.subtitles[state.selectedSubtitle].text.
        replace(/([!+*?^$\n{}()|\+ /.,;]+)/g, ' ').split(' ').filter((e) => e !== '')

    if (state.subtitles[state.selectedSubtitle].userText && state.level != 2) {
        state.userWords = state.subtitles[state.selectedSubtitle].userText.split(' ').filter(e => e.text !== "")
            .map((e, index) => {
                return {
                    similarity: window.stringSimilarity.compareTwoStrings(
                        e, state.splitedSubtitle[index] || ""), text: e
                }
            })
    } else { state.userWords = [] }

    if (state.splitedSubtitle.length > state.userWords.length && state.level == "1") {
        state.userWords.push({ similarity: 0, text: "" })
        state.selectedWord = state.userWords.length - 1
    }
    if (state.level == 2) {
        state.splitedSubtitle = state.splitedSubtitle.map(e => e.toLowerCase())
        state.shuffledSubtitle = [...state.splitedSubtitle]
        shuffleArray(state.shuffledSubtitle)


    }
}




export const subtitleSlice = createSlice({
    name: "subtitle",
    initialState: {
        statusSubtitle: {},
        subtitles: [],
        selectedSubtitle: 0,
        selectedWord: 0,
        splitedSubtitle: [],
        shuffledSubtitle: [],
        userWords: [{ similarity: 0, text: "" }],
        level: '1'

    },

    reducers: {
        setSubtitles: (state, action) => {
            let subtitles = [...action.payload]

            subtitles.forEach((element, index) => {

                if (subtitles.length - 1 === index) {
                    return;
                }

                if (+element.start + +element.duration > +subtitles[index + 1].start) {
                    element.duration = +subtitles[index + 1].start - +element.start;
                }
            });
            return { ...state, subtitles: [...subtitles] }
        },

        setStatusSubtitle: (state, action) => {
            state.statusSubtitle = action.payload
        },

        // {userText: , videoId: }
        setUserTextInSelectedSubtitle: (state, action) => {
            if (action.payload.event.key === " ") {
                state.subtitles[state.selectedSubtitle].userText = state.userWords.map(e => e.text).join(" ").trim()
                // console.log(state.subtitles[state.selectedSubtitle].userText)
                saveUserTextToDataBase(state, action)

            }
        },

        setSelectedSubtitle: (state, action) => { selectSubtitle(state, action.payload) },

        setSelectedWordText: (state, action) => {
            {
                state.userWords[state.selectedWord].text = action.payload
                state.userWords[state.selectedWord].similarity = window.stringSimilarity.compareTwoStrings(
                    state.splitedSubtitle[state.selectedWord].toLowerCase() || "", (
                    state.userWords[state.selectedWord].text ?
                        state.userWords[state.selectedWord].text.toLowerCase() :
                        state.userWords[state.selectedWord].text))
            }
        },

        setLevel: (state, action) => {
            state.level = action.payload
            selectSubtitle(state, state.selectedSubtitle)
        },

        setSelectedWordId: (state, action) => {
            state.selectedWord = action.payload
        },

        setUserWords: (state, action) => {
            if (action.payload.key === " ") {
                if (state.splitedSubtitle.length > state.userWords.length) {
                    state.userWords.push({ similarity: 0, text: "" })
                    state.selectedWord = state.userWords.length - 1

                }
                else {
                    state.selectedWord = null
                    selectSubtitle(state, state.selectedSubtitle + 1)


                }
            }


            if (state.selectedWord != 0 && action.payload.target.value == "" && action.payload.key === "Backspace") {


                state.userWords.pop()
                state.selectedWord = state.userWords.length - 1
            }

        },

        setSplitedSubtitle: (state, action) => {

        },


        setOnDrugEndSubtitle: (state, action) => {


            let drop = action.payload.e
            if (drop.destination.droppableId == drop.source.droppableId) {
                let [reorderedItem] = state[drop.destination.droppableId].splice(drop.source.index, 1)
                state[drop.destination.droppableId].splice(drop.destination.index, 0, reorderedItem)

                if (drop.source.droppableId == "userWords" && drop.destination.droppableId == "userWords") {
                    state.userWords[drop.source.index].similarity = window.stringSimilarity.compareTwoStrings(
                        state.userWords[drop.source.index].text, state.splitedSubtitle[drop.source.index])
                    state.userWords[drop.destination.index].similarity = window.stringSimilarity.compareTwoStrings(
                        state.userWords[drop.destination.index].text, state.splitedSubtitle[drop.destination.index])

                }
            }
            else {
                if (drop.source.droppableId == "shuffledSubtitle" && drop.destination.droppableId == "userWords") {

                    state[drop.destination.droppableId].splice(
                        drop.destination.index, 0,
                        {
                            similarity: window.stringSimilarity.compareTwoStrings(
                                state[drop.source.droppableId][drop.source.index], state.splitedSubtitle[drop.destination.index]),
                            text: state[drop.source.droppableId][drop.source.index]
                        })
                    state[drop.source.droppableId].splice(drop.source.index, 1)

                }
                if (drop.source.droppableId == "userWords" && drop.destination.droppableId == "shuffledSubtitle") {
                    state[drop.destination.droppableId].splice(
                        drop.destination.index, 0,
                        state[drop.source.droppableId][drop.source.index].text)
                    state[drop.source.droppableId].splice(drop.source.index, 1)
                }
            }
            if (drop.destination.index != drop.source.index) {
                state.subtitles[state.selectedSubtitle].userText = state.userWords.map(e => e.text).join(" ").trim()
                saveUserTextToDataBase(state, action)
            }

        }

    },

    extraReducers: (builder) => {
        builder.addCase(fetchValidSubtitles.fulfilled, (state, action) => {
            state.statusSubtitle = action.payload
        })


    }
})





export const fetchSubtitles = (videoId, title) => (dispatch) => {
    fetch('/captions/' + videoId + "/" + title).then(r => r.json()).then(r => { dispatch(subtitleSlice.actions.setSubtitles(r)) });
}



export const saveUserSubtitles = (videoId) => (dispatch) => {
    fetch('/captions/' + videoId).then(r => r.json()).then(r => { dispatch(subtitleSlice.actions.setSubtitles(r)) });
}

export { fetchValidSubtitles }

export const {
    setStatusSubtitle,
    setUserTextInSelectedSubtitle,
    setSelectedSubtitle,
    setSelectedWordId,
    setSplitedSubtitle,
    setUserWords,
    setSelectedWordText,
    setOnDrugEndSubtitle,
    setLevel
} = subtitleSlice.actions

export default subtitleSlice.reducer