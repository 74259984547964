import { useSelector, useDispatch } from 'react-redux'
import Word from './WordEasy/Word'
import {
    setUserTextInSelectedSubtitle,
    setSelectedSubtitle,
    setSelectedWord,
    setSplitedSubtitle,
    setUserWords,
    setOnDrugEndSubtitle,
} from "../../../../../features/subtitles/subtitleSlice"

import styles from "./EasyUSE.module.css"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

function progressProcessor(subtitles) {

    let userTextRateSum = 0
    subtitles.forEach(e => {
        if (e.userText)
            userTextRateSum += window.stringSimilarity.compareTwoStrings(e.text || " ", e.userText || " ")
    });
    return (userTextRateSum / subtitles.length) * 100
}

function EasyLevelUSE(subtitleInputRef) {
    let dispatch = useDispatch()

    const { subtitles,
        selectedSubtitle,
        selectedWord,
        splitedSubtitle,
        userWords,
        shuffledSubtitle } = useSelector(state => state.subtitle)
    const { lastVideo } = useSelector(state => state.userSlice)



    return (<div>

        <DragDropContext onDragEnd={(e) => {
            let progress = progressProcessor(subtitles)

            dispatch(setOnDrugEndSubtitle({ e, lastVideo, progress }))
        }}>
            <Droppable droppableId="shuffledSubtitle" direction='horizontal'>
                {(provided) => (
                    <ul
                        className='flex flex-row justify-center flex-wrap'
                        ref={provided.innerRef} {...provided.droppableProps}>
                        {shuffledSubtitle.map((word, index) => {

                            return <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(provided) => (
                                    <li className={styles.subtitleWord}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {word}
                                    </li>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>

            <Droppable droppableId="userWords" direction='horizontal'>
                {(provided) => (
                    <ul className={styles.userWordArea}
                        ref={provided.innerRef} {...provided.droppableProps}>
                        {userWords.map((word, index) => (
                            <Draggable key={index} draggableId={(splitedSubtitle.length + index + 1).toString()} index={index}>
                                {(provided) => (
                                    <li className={`${styles.userWord} ${word.similarity > 0.9 ? styles.userWordSuccess : styles.userWordWrong}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {word.text}
                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}

                    </ul>
                )}
            </Droppable>


        </DragDropContext>


    </div>)

}

export default EasyLevelUSE
