import styles from './header.module.css'
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../../features/user/userSlice'
import { fetchUserData, setTargetLang } from '../../features/user/userSlice'
import GSignInBtn from "./GSignInBtn"
import VideoIdModal from '../VideoPractice/player/VideoIdModal/VideoIdModal'
import IcoButton from '../ui/IcoButton/IcoButton'
import SearchVideoForm from '../ui/SearchVideoForm/SearchVideoForm'



import { useState, useRef, useEffect } from "react"
import SearchDropDown from '../ui/SearchDropDown/SearchDropDown'
import ButtonDefault from '../ui/ButtonDefault/ButtonDefault'




const listOfLang = [
    { shortLang: "ps", fullLang: "Pashto" },
    { shortLang: "af", fullLang: "Afrikaans" },
    { shortLang: "ak", fullLang: "Akan" },
    { shortLang: "sq", fullLang: "Albanian" },
    { shortLang: "am", fullLang: "Amharic" },
    { shortLang: "ar", fullLang: "Arabic" },
    { shortLang: "hy", fullLang: "Armenian" },
    { shortLang: "as", fullLang: "Assamese" },
    { shortLang: "ay", fullLang: "Aymara" },
    { shortLang: "az", fullLang: "Azerbaijani" },
    { shortLang: "eu", fullLang: "Basque" },
    { shortLang: "be", fullLang: "Belarusian" },
    { shortLang: "bn", fullLang: "Bengali" },
    { shortLang: "bho", fullLang: "Bhojpuri" },
    { shortLang: "bs", fullLang: "Bosnian" },
    { shortLang: "bg", fullLang: "Bulgarian" },
    { shortLang: "my", fullLang: "Burmese" },
    { shortLang: "ceb", fullLang: "Cebuano" },
    { shortLang: "da", fullLang: "Danish" },
    { shortLang: "dv", fullLang: "Divehi" },
    { shortLang: "en", fullLang: "English" },
    { shortLang: "eo", fullLang: "Esperanto" },
    { shortLang: "et", fullLang: "Estonian" },
    { shortLang: "ee", fullLang: "Ewe" },
    { shortLang: "fil", fullLang: "Filipino" },
    { shortLang: "fi", fullLang: "Finnish" },
    { shortLang: "fr", fullLang: "French" },
    { shortLang: "gl", fullLang: "Galician" },
    { shortLang: "ka", fullLang: "Georgian" },
    { shortLang: "el", fullLang: "Greek" },
    { shortLang: "gn", fullLang: "Guaran?" },
    { shortLang: "gu", fullLang: "Gujarati" },
    { shortLang: "ht", fullLang: "Haitian" },
    { shortLang: "ha", fullLang: "Hausa" },
    { shortLang: "haw", fullLang: "Hawaiian" },
    { shortLang: "iw", fullLang: "Hebrew" },
    { shortLang: "hi", fullLang: "Hindi" },
    { shortLang: "hmn", fullLang: "Hmong" },
    { shortLang: "ig", fullLang: "Igbo" },
    { shortLang: "id", fullLang: "Indonesian" },
    { shortLang: "ga", fullLang: "Irish" },
    { shortLang: "is", fullLang: "Icelandic" },
    { shortLang: "it", fullLang: "Italian" },
    { shortLang: "ja", fullLang: "Japanese" },
    { shortLang: "jv", fullLang: "Javanese" },
    { shortLang: "yi", fullLang: "Yiddish" },
    { shortLang: "km", fullLang: "Khmer" },
    { shortLang: "kn", fullLang: "Kannada" },
    { shortLang: "ca", fullLang: "Catalan" },
    { shortLang: "kk", fullLang: "Kazakh" },
    { shortLang: "zh-Hans", fullLang: "Chinese (Simplified)" },
    { shortLang: "zh-Hant", fullLang: "Chinese (Traditional)" },
    { shortLang: "rw", fullLang: "Kinyarwanda" },
    { shortLang: "ky", fullLang: "Kyrgyz" },
    { shortLang: "ko", fullLang: "Korean" },
    { shortLang: "co", fullLang: "Corsican" },
    { shortLang: "kri", fullLang: "Krio" },
    { shortLang: "hr", fullLang: "Croatian" },
    { shortLang: "ku", fullLang: "Kurdish" },
    { shortLang: "lo", fullLang: "Lao" },
    { shortLang: "la", fullLang: "Latin" },
    { shortLang: "lv", fullLang: "Latvian" },
    { shortLang: "ln", fullLang: "Lingala" },
    { shortLang: "lt", fullLang: "Lithuanian" },
    { shortLang: "lg", fullLang: "Luganda" },
    { shortLang: "lb", fullLang: "Luxembourgish" },
    { shortLang: "mk", fullLang: "Macedonian" },
    { shortLang: "mg", fullLang: "Malagasy" },
    { shortLang: "ms", fullLang: "Malay" },
    { shortLang: "ml", fullLang: "Malayalam" },
    { shortLang: "mt", fullLang: "Maltese" },
    { shortLang: "mi", fullLang: "M?ori" },
    { shortLang: "mr", fullLang: "Marathi" },
    { shortLang: "mn", fullLang: "Mongolian" },
    { shortLang: "nl", fullLang: "Dutch" },
    { shortLang: "ne", fullLang: "Nepali" },
    { shortLang: "nso", fullLang: "Northern Sotho" },
    { shortLang: "no", fullLang: "Norwegian" },
    { shortLang: "ny", fullLang: "Nyanja" },
    { shortLang: "or", fullLang: "Odia" },
    { shortLang: "om", fullLang: "Oromo" },
    { shortLang: "fa", fullLang: "Persian" },
    { shortLang: "pl", fullLang: "Polish" },
    { shortLang: "pt", fullLang: "Portuguese" },
    { shortLang: "pa", fullLang: "Punjabi" },
    { shortLang: "qu", fullLang: "Quechua" },
    { shortLang: "ro", fullLang: "Romanian" },
    { shortLang: "ru", fullLang: "Russian" },
    { shortLang: "sm", fullLang: "Samoan" },
    { shortLang: "sa", fullLang: "Sanskrit" },
    { shortLang: "sr", fullLang: "Serbian" },
    { shortLang: "sn", fullLang: "Shona" },
    { shortLang: "sd", fullLang: "Sindhi" },
    { shortLang: "si", fullLang: "Sinhala" },
    { shortLang: "gd", fullLang: "Scottish Gaelic" },
    { shortLang: "sk", fullLang: "Slovak" },
    { shortLang: "sl", fullLang: "Slovenian" },
    { shortLang: "so", fullLang: "Somali" },
    { shortLang: "es", fullLang: "Spanish" },
    { shortLang: "su", fullLang: "Sundanese" },
    { shortLang: "sv", fullLang: "Swedish" },
    { shortLang: "sw", fullLang: "Swahili" },
    { shortLang: "st", fullLang: "Southern Sotho" },
    { shortLang: "tg", fullLang: "Tajik" },
    { shortLang: "ta", fullLang: "Tamil" },
    { shortLang: "tt", fullLang: "Tatar" },
    { shortLang: "te", fullLang: "Telugu" },
    { shortLang: "th", fullLang: "Thai" },
    { shortLang: "ti", fullLang: "Tigrinya" },
    { shortLang: "cs", fullLang: "Czech" },
    { shortLang: "ts", fullLang: "Tsonga" },
    { shortLang: "tr", fullLang: "Turkish" },
    { shortLang: "tk", fullLang: "Turkmen" },
    { shortLang: "de", fullLang: "German" },
    { shortLang: "ug", fullLang: "Uighur" },
    { shortLang: "uk", fullLang: "Ukrainian" },
    { shortLang: "hu", fullLang: "Hungarian" },
    { shortLang: "ur", fullLang: "Urdu" },
    { shortLang: "uz", fullLang: "Uzbek" },
    { shortLang: "vi", fullLang: "Vietnamese" },
    { shortLang: "fy", fullLang: "Western Frisian" },
    { shortLang: "cy", fullLang: "Welsh" },
    { shortLang: "xh", fullLang: "Xhosa" },
    { shortLang: "yo", fullLang: "Yoruba" },
    { shortLang: "zu", fullLang: "Zulu" }
];




const ProfileDropDown = (props) => {
    const { email, nickName, pictureLink, locale, videos, targetLanguage } = props.profileData

    const [state, setState] = useState(false)
    const profileRef = useRef()



    useEffect(() => {
        const handleDropDown = (e) => {
            if (profileRef.current && !profileRef.current.contains(e.target)) {
                setState(false);
            }
        };

        document.addEventListener('click', handleDropDown);

        return () => {
            document.removeEventListener('click', handleDropDown);
        };
    }, []);


    return (
        <div className={`relative z-20 ${props.class}`}>
            <div className="flex items-center space-x-4">
                <button ref={profileRef} className="w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 ring-2 lg:focus:ring-indigo-600"
                    onClick={() => setState(!state)}
                >
                    <img
                        src='https://static.vecteezy.com/system/resources/thumbnails/005/176/777/small/user-avatar-line-style-free-vector.jpg'
                        className="w-full h-full rounded-full"
                    />
                </button>
                <div className="lg:hidden">
                    <span className="block">{nickName}</span>
                    <span className="block text-sm text-gray-500">{email}</span>
                </div>
            </div>
            <ul className={`bg-white top-12 right-0 mt-5 space-y-5 lg:absolute lg:border lg:rounded-md lg:text-sm lg:w-52 lg:shadow-md lg:space-y-0 lg:mt-0 ${state ? '' : 'lg:hidden'}`}>
                <li>
                    <Link key="profile" className="block cursor-pointer text-gray-600 lg:hover:bg-gray-50 lg:p-2.5" to="/profile">
                        Profile
                    </Link>



                </li>
                <li>
                    <Link key="cpwd" className="block cursor-pointer text-gray-600 lg:hover:bg-gray-50 lg:p-2.5" to="/cpwd">
                        Change password
                    </Link>
                </li>
                <li>
                    <div key="logout" className="block cursor-pointer text-gray-600 lg:hover:bg-gray-50 lg:p-2.5" onClick={() => { window.location.href = '/logout' }}>Logout
                    </div>
                </li>

            </ul>
        </div>
    )
}

export default () => {

    let dispatch = useDispatch()
    let { email, nickName, pictureLink, locale, videos, targetLanguage, verified } = useSelector(state => state.userSlice)

    useEffect(() => {
        dispatch(fetchUserData());

    }, [dispatch]);

    const [menuState, setMenuState] = useState(false)


    const navigation = [
        { title: "How to use?", path: "/howtouse" },

    ]
    return (
        <nav className="bg-white border-b">
            <div className="flex items-center space-x-8 py-3 px-4 max-w-screen-xl mx-auto md:px-8">
                <div className="flex-none lg:flex-initial">
                    <Link to="/homepage">
                        <img
                            className='max-sm:w-12 max-sm:h-5 w-24 h-10'
                            src="/static/img/logo.png"

                            alt="logo"
                        />


                    </Link>
                </div>
                <div className="flex-1 flex items-center justify-between">
                    <div className={`bg-white absolute z-20 w-full top-16 left-0 p-4 border-b lg:static lg:block lg:border-none ${menuState ? '' : 'hidden'}`}>
                        <ul className="mt-12 space-y-5 lg:flex lg:space-x-6 lg:space-y-0 lg:mt-0">
                            {
                                navigation.map((item, idx) => (
                                    <li key={idx} className="text-gray-600 hover:text-gray-900">
                                        <Link to={item.path}>
                                            {item.title}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>


                        <ProfileDropDown
                            profileData={{ email, nickName, pictureLink, locale, videos, targetLanguage }}

                            class="mt-5 pt-5 border-t lg:hidden"
                        />
                    </div>
                    {email ?
                        <div className="flex-1 flex items-center justify-end space-x-2 sm:space-x-6">

                            {verified ? <SearchDropDown title="Target language" menuItems={listOfLang} action={setTargetLang} /> : ''}

                            {verified ? <SearchVideoForm></SearchVideoForm>
                                : <p className=" w-full text-sm">Your email is not verified</p>}

                            <ProfileDropDown
                                profileData={{ email, nickName, pictureLink, locale, videos, targetLanguage }}
                                class="hidden lg:block"
                            />
                            <button
                                className="outline-none text-gray-400 block lg:hidden"
                                onClick={() => setMenuState(!menuState)}
                            >
                                {
                                    menuState ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                                        </svg>
                                    )
                                }
                            </button>
                        </div>
                        :
                        <div className='flex justify-center items-center gap-2'>
                            <Link to='/loginform'><ButtonDefault>Login</ButtonDefault></Link>
                            <p className='font-bold text-xs text-slate-800'>or</p>
                            <Link to="/registerform"><p className='font-bold text-xs text-purple-500'>Create new account</p></Link>
                        </div>

                    }
                </div>
            </div>
        </nav>
    )
}