

function Word({word, index}){

    return (<li>
        {word}
    </li>)
}


export default Word