import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { changePwd } from "../features/user/userSlice";



function pwdValidation(pwd, pwdConfirmation) {
    return pwd.length > 5 && pwd === pwdConfirmation;
}

export default () => {
    const dispatch = useDispatch()
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const password = useRef();


    useEffect(() => {
        setPasswordIsValid(pwdValidation(newPassword, newPasswordConfirmation));

        if (newPassword.length < 6) {
            setPasswordError("Password should be at least 6 characters");
        } else if (newPassword !== newPasswordConfirmation) {
            setPasswordError("Passwords do not match");
        } else {
            setPasswordError("");
        }
    }, [newPassword, newPasswordConfirmation]);

    return (
        <section className=" m-10 max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
            <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Change password</h2>

            <form>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                    <div>
                        <label className="text-gray-700 dark:text-gray-200" htmlFor="emailAddress">Old password</label>
                        <input ref={password} id="emailAddress" type="password" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                    </div>

                    <div>
                        <label className="text-gray-700 dark:text-gray-200" htmlFor="password">New password</label>
                        <input onChange={(e) => setNewPassword(e.target.value)} id="password" type="password" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                        {newPassword.length < 6 && <p className="text-red-500">Password should be at least 6 characters</p>}
                    </div>

                    <div>
                        <label className="text-gray-700 dark:text-gray-200" htmlFor="passwordConfirmation">New password confirmation</label>
                        <input onChange={(e) => setNewPasswordConfirmation(e.target.value)} id="passwordConfirmation" type="password" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                        {newPassword !== newPasswordConfirmation && <p className="text-red-500">Passwords do not match</p>}
                    </div>
                </div>

                <div className="flex justify-end mt-6">
                    {passwordIsValid ? (
                        <button onClick={(e) => {
                            e.preventDefault()
                            dispatch(changePwd({
                                password: password.current.value,
                                new_password: newPassword

                            }))
                        }}

                            className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">Change password</button>
                    ) : (
                        <div className="cursor-not-allowed grayscale px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">Change password</div>
                    )}
                </div>
            </form>
        </section>
    );
};
