import Modal from "../ui/Modal/Modal"
import SearchVideoForm from "../ui/SearchVideoForm/SearchVideoForm"


export default (props) => {
    return (
        <Modal active={props.active} setActive={props.setActive}>
            <SearchVideoForm></SearchVideoForm>
        </Modal>
    )
}