import { useSelector, useDispatch } from 'react-redux'
import Word from './Word/Word'
import {
    setUserTextInSelectedSubtitle,
    setSelectedSubtitle,
    setSelectedWord,
    setSplitedSubtitle,
    setUserWords,
} from "../../../../../features/subtitles/subtitleSlice"


function HardLevelUSE(subtitleInputRef) {
    const dispatch = useDispatch()


    const { subtitles,
        selectedSubtitle,
        selectedWord,
        splitedSubtitle,
        userWords } = useSelector(state => state.subtitle)


    return (
        <div className='p-2 bg-slate-100'>
            {userWords.map((e, id) => <Word index={id} word={e} />)}

        </div>

    )


}



export default HardLevelUSE