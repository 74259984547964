import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Card from "../components/ui/Card/Card"
import SearchVideoModal from '../components/SearchVideoModal/SearchVideoModal';

const Profile = () => {
  let dispatch = useDispatch()
  let [searchModal, setSearchModal] = useState(false)
  let { email, nickName, pictureLink, locale, videos } = useSelector(state => state.userSlice)

  if (videos.length == 0) {
    return (
      <>
        <div class="flex justify-center items-center h-screen">
          <div class="text-center">
            <h1 class="text-5xl font-bold text-gray-800 mb-4">No videos added yet!</h1>
            <p class="text-lg text-gray-600">Start by adding some videos to your collection.</p>
          </div>
        </div></>
    )
  }
  return (
    <div className="mt-12 grid gap-2 sm:grid-cols-2 lg:grid-cols-4">
      {!(videos.length == 0) ? videos.map((e, key) => {
        return (<Card e={e} index={key} />)
      }) : ''
      }
    </div>
  );
};

export default Profile;