import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from "./store/store"
import Header from './components/Header/HeaderV1.jsx'
import Footer from './components/Footer/Footer.jsx';
import ErrorAlert from './components/ui/ErrorAlert/ErrorAlert.jsx';
import SuccessAlert from "./components/ui/SuccessAlert/SuccessAlert.jsx"
import LoadingModal from './components/loadingModal/LoadingModal.jsx';
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <Provider store={store}>
        <BrowserRouter>
            <LoadingModal className=" z-40" />
            <ErrorAlert />
            <SuccessAlert />
            <Header />
            <App />
            <Footer />
        </BrowserRouter>
    </Provider>

);


