import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from "../Timeline/timeline.module.css"

let started;


function Pointer({ isPlaying, player, ratio, setTimeLineScroll, userActivity }) {
    let dispatch = useDispatch()
    let { isRepeat } = useSelector(state => state.player)
    const { subtitles,
        selectedSubtitle,
        selectedWord,
        splitedSubtitle,
        userWords,
        shuffledSubtitle } = useSelector(state => state.subtitle)

    let [currentTime, setCurrentTime] = useState(0)


    useEffect(
        () => {
            if (isPlaying) {
                started = setInterval(timelineProcess, 100)

            }
            else {
                clearInterval(started)
            }
        }, [isPlaying])


    function timelineProcess() {

        setCurrentTime(+ player.current.internalPlayer.getCurrentTime())

    }

    if (!isRepeat) {
        if (!(+currentTime < (+subtitles[selectedSubtitle].start + +subtitles[selectedSubtitle].duration + 0.4) && +currentTime > +subtitles[selectedSubtitle].start - 1)) {
            player.current.internalPlayer.seekTo(+subtitles[selectedSubtitle].start)
        }


    }

    return (<div style={{ left: +currentTime * +ratio + "px" }} className={styles.pointer}>

    </div>)
}

export default Pointer;