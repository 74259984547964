import React from 'react'
import styles from './Card.module.css'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { deleteVideo } from '../../../features/user/userSlice';
import { setVideoId } from '../../../features/player/playerSlice';
import { setLastVideo } from '../../../features/user/userSlice';


function Card({ e, index }) {

    let dispatch = useDispatch()

    return (
        <article className="max-w-md mx-auto mt-4 shadow-lg border rounded-md duration-300 hover:shadow-sm relative" key={index}>

            <div className={`p-2 text-gray-400 rounded-md hover:bg-gray-100 ${styles.CloseButton}`} onClick={() => { dispatch(deleteVideo(e.vid)) }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>

            <div class="mb-6 h-1 w-full bg-neutral-200 dark:bg-neutral-600">
                <div class="h-1 bg-green-500" style={{ width: `${e.progress}%` }}></div>
            </div>

            <Link to={`../captions`} onClick={() => dispatch(setLastVideo(e.vid))}>
                <img src={`https://img.youtube.com/vi/${e.vid}/0.jpg`} loading="lazy" alt="title" className="w-auto h-auto rounded-t-md" />

                <div className="pt-3 ml-4 mr-2 mb-3">
                    <h3 className="text-xl text-gray-900">
                        {e.title}
                    </h3>

                </div>
            </Link>
        </article>

    )

}


export default Card