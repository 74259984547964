import ButtonDefault from "../components/ui/ButtonDefault/ButtonDefault"
import { useDispatch } from "react-redux"
import { resendVerification, setLoading } from "../features/user/userSlice"

export default () => {
    const dispatch = useDispatch()
    return (
        <div className="min-h-screen bg-white flex flex-col items-center justify-center">
            <div className="max-w-md text-center">
                <h1 className="text-2xl font-semibold text-[#6B4EFF] mb-4">
                    Verify Your Email Address
                </h1>
                <p className="text-gray-600 mb-6">
                    Follow the link we have sent to your email. Check your spam folder in
                    case you didn't find the verification message.
                </p>
                <div onClick={() => {
                    dispatch(resendVerification())
                    dispatch(setLoading(true))
                }}>
                    <ButtonDefault
                        className="px-6 py-2 rounded bg-[#6B4EFF] text-white font-medium hover:bg-[#5a42d1] transition-colors duration-150 ease-in-out"

                    >
                        Resend verification message
                    </ButtonDefault>
                </div>
            </div>
        </div>
    );
}

