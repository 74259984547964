import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVideoId } from '../../../../features/player/playerSlice';
import Modal from '../../../ui/Modal/Modal';
import { Link } from 'react-router-dom';


function getVideoIdByUrl(url){
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}

function VideoIdModal(){
    const dispatch = useDispatch()
    
    let [activeVideoIdInput, setActiveVideoIdInput] = useState(false)

    return (
        <div>
            <button className='btn btn-outline-success' onClick={()=> {setActiveVideoIdInput(true)}} >Search video</button>
            <Modal active={activeVideoIdInput} setActive={setActiveVideoIdInput}>
                <input id='videoIdInput' type="text"></input>
                <Link to={"/captions"} onClick={()=> {  dispatch(setVideoId(getVideoIdByUrl(document.getElementById('videoIdInput').value)))}} >Try this one</Link>
            </Modal>
        </div>

    )
}

export default VideoIdModal;