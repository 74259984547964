import React, { useState } from 'react';
import Player from '../components/VideoPractice/player/Player';




function VideoPractice() {



  return (
    <div >

      <Player />


    </div>
  );
}

export default VideoPractice;
