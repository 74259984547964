import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLastVideo } from '../../../features/user/userSlice';
import { Link } from 'react-router-dom';
import { fetchValidSubtitles, setStatusSubtitle } from '../../../features/subtitles/subtitleSlice';



function getVideoIdByUrl(url) {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}


function DisabledButton() {
    return (
        <div className='max-md:p-1 px-2 py-2 text-sm text-white bg-indigo-300 rounded-lg cursor-not-allowed'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clipRule="evenodd" />
            </svg>
        </div>
    )
}


function ErrorAlert({ error, setShowError }) {
    return (
        <div className='absolute top-1/4 left-1/2 transform -translate-y-1/2 transform -translate-x-1/2 z-10'>
            <div className="mt-12 mx-4 px-4 rounded-md border-l-4 border-red-500 bg-red-50 md:max-w-2xl md:mx-auto md:px-8">
                <div className="flex justify-between py-3">
                    <div className="flex">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="self-center ml-3">
                            <span className="text-red-600 font-semibold">
                                Error
                            </span>
                            <p className="text-red-600 mt-1">
                                {error}
                            </p>
                        </div>
                    </div>
                    <button className="self-start text-red-500" onClick={() => { setShowError(false) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

function SuccessAlert() {

}


export default () => {
    const dispatch = useDispatch()
    const statusSubtitle = useSelector(state => state.subtitle.statusSubtitle)
    const [isUrlValid, setIsUrlValid] = useState(false)
    const [showError, setShowError] = useState(false)
    const ref = useRef()


    useEffect(() => {
        if (statusSubtitle.status === "success") {
            setShowError(false);
            setIsUrlValid(true);
        } else if (statusSubtitle.status === "error") {
            setShowError(statusSubtitle.error);
            setIsUrlValid(false);
        }
    }, [statusSubtitle]);




    function onSearchChangeHandler(e) {
        let vid = getVideoIdByUrl(document.getElementById('videoSearchInput').value)

        if (vid) {
            dispatch(fetchValidSubtitles(vid))
        }
    }

    return (
        <form className="flex items-center space-x-2 border rounded-md p-1 max-md:w-25">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 flex-none text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>

            <input
                id='videoSearchInput'
                className="w-full outline-none appearance-none placeholder-gray-500 text-gray-500 sm:w-auto"
                type="text"
                placeholder="Youtube video link"
                onChange={onSearchChangeHandler}


            />
            {showError ? <ErrorAlert error={showError} setShowError={setShowError} /> : ''}

            {isUrlValid ?
                <Link className="block max-md:p-1 px-2.5 py-2.5 text-indigo-600 duration-150 bg-indigo-50 rounded-lg hover:bg-indigo-100 active:bg-indigo-200"
                    to={"/captions"}
                    onClick={() => {
                        dispatch(setLastVideo(getVideoIdByUrl(document.getElementById('videoSearchInput').value)))
                        document.getElementById('videoSearchInput').value = ''
                        dispatch(setStatusSubtitle({}))
                        setIsUrlValid(false)
                    }} >

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clipRule="evenodd" />
                    </svg>
                </Link>
                :
                <DisabledButton></DisabledButton>}

        </form>)
}