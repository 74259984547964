import React, { useState, useRef } from 'react'; // ���������� ���������� useState � useRef
import "./PlayerButton.css";
import { useSelector } from 'react-redux'; // useDispatch �� ������������, ������� �� �������������

function PlayButton() {
    const { isPlaying, playerRef: player } = useSelector(state => state.player);


    return (

        <button
            onClick={() => {
                if (isPlaying) {
                    player.current.internalPlayer.pauseVideo();
                } else {
                    player.current.internalPlayer.playVideo();
                }
            }}
            className="p-1 w-12 h-12 bg-slate-500 rounded-full shadow-md hover:shadow-lg transition duration-150 ease-in-out flex items-center justify-center"

        >
            {isPlaying ? (
                // Pause Icon
                <svg viewBox="0 0 24 24" width="24" height="24" className=""><path fill="currentColor" d="M14,19H18V5H14M6,19H10V5H6V19Z" /></svg>
            ) : (
                // Play Icon
                <svg viewBox="0 0 24 24" width="24" height="24" className=""><path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" /></svg>
            )}
        </button>

    );
}

export default PlayButton;
