import { React, useRef } from 'react'
import { useSelector } from 'react-redux'

function Volume() {
    let player = useSelector(state => state.player.playerRef)
    let volumeInput = useRef()

    function changeVolume(e) {
        player.current.internalPlayer.setVolume(e.target.value)
    }

    return (
        <div className='mx-1 w-1/6 relative max-md:hidden'>
            {/* <label htmlFor="volume"></label>
            <input ref={volumeInput} id='volume' type="range" min="0" max="100" onChange={changeVolume} /> */}

            <div>
                <label
                    for="volume"
                    class="mb-1 absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-3/4 inline-block text-slate-800 dark:text-neutral-800"
                >Volume</label>
                <input
                    ref={volumeInput} id='volume' min="0" max="100" onChange={changeVolume}
                    type="range"
                    class="transparent h-[4px] w-full cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600" />
            </div>
        </div>
    )
}


export default Volume