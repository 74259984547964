



export default () => {


    return (<footer class="bg-neutral-100 text-center text-neutral-600     ">

        <div class="flex items-center justify-center border-b-2 border-neutral-200 p-6   lg:justify-between ">
            <div class="mr-12 hidden md:block ">
                <span>Get connected with us:</span>
            </div>
            <div class="flex justify-center items-center ">
                {/* FACEBOOK */}
                <a
                    href="https://www.facebook.com/profile.php?id=100078474537996"
                    class="mr-6 text-neutral-600   "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg "
                        class="h-4 w-4 "
                        fill="currentColor "
                        viewBox="0 0 24 24 "
                    >
                        <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z " />
                    </svg>
                </a>
                {/* YOUTUBE */}
                <a
                    href="https://www.youtube.com/channel/UCDK4Fuuk6YyqIr7CGtb4ERw"
                    class="mr-6 text-neutral-600   "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg "
                        class="h-5 w-5 "
                        fill="currentColor "
                        viewBox="0 0 576 512 "
                    >
                        <path
                            d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z "
                            fillRule="evenodd "
                            clipRule="evenodd "
                        />
                    </svg>
                </a>
                {/* Insta */}
                {/* <a
                    href="#! "
                    class="mr-6 text-neutral-600   "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg "
                        class="h-4 w-4 "
                        fill="currentColor "
                        viewBox="0 0 24 24 "
                    >
                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z " />
                    </svg>
                </a> */}
                {/* LinkedIn */}
                {/* <a
                    href="#! "
                    class="mr-6 text-neutral-600   "
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg "
                        class="h-4 w-4 "
                        fill="currentColor "
                        viewBox="0 0 24 24 "
                    >
                        <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z " />
                    </svg>
                </a> */}
                <a href="https://t.me/itrainsitpours" class="text-neutral-600   ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                    </svg>
                </a>
            </div>
        </div>


        <div class="mx-6 pt-8 pb-4 text-center ">


            <div class="mb-6 ">
                <h5 class="mb-2 font-bold uppercase ">Explore, Learn, Connect</h5>

                <p class="mb-4 ">
                    Unlocking the World of Languages with Listen and Recognize!
                </p>
            </div>

        </div>

        <div class="bg-neutral-200 p-6 text-center   flex justify-center items-center ">

            <span>� 2024 Copyright: </span>

            <a class="mx-3 " href="# ">
                <img
                    src="/static/img/logo.png "
                    class="h-5 "
                    alt="Listen and recognize "
                    loading="lazy "
                />
            </a>

            <a
                class="font-semibold text-neutral-600   "
                href="/homepage "
            >
                Listen and recognize!
            </a>

        </div>


    </footer>)
}