import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


const fetchUserData = createAsyncThunk('user/fetchUserData', async () => {
    const response = await fetch('/gus');
    const data = await response.json();
    return data;
});

const setLastVideo = createAsyncThunk('user/setLastVideo', async (vid) => {
    const response = await fetch(`/slv/${vid}`, {
        method: 'POST',
    })
    return vid
})

const changePwd = createAsyncThunk('user/changePwd', async (data) => {
    const response = await fetch('/chpwd', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const result = await response.json()
    return result
})

const resendVerification = createAsyncThunk('user/resendVerification', async () => {
    const response = await fetch('/resendtoken', { method: "POST" });
    const data = await response.json();
    return data;
});

const loginUser = createAsyncThunk('user/loginUser', async (data) => {
    const response = await fetch('/login', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    const result = await response.json()
    return result
})

const registerUser = createAsyncThunk('user/registerUser', async (data) => {
    const response = await fetch('/register', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    const result = await response.json()
    return result
})



const deleteVideo = createAsyncThunk('user/deleteVideo', async (vid) => {
    const response = await fetch(`/dlvid/${vid}`, {
        method: 'DELETE',
    });
    return vid
})

const setTargetLang = createAsyncThunk('user/setTargetLang', async (lang) => {
    const response = await fetch(`/setlang/${lang}`, {
        method: 'POST',
    });
    return lang
})
//   fetch('/gus').then(r => r.json()).then(r => { dispatch(userSlice.actions.setUserData(r)) });

export const userSlice = createSlice({
    name: "userSlice",
    initialState: {

        email: "",
        nickName: "",
        pictureLink: "",
        locale: "",
        targetLanguage: "",
        verified: "",
        lastVideo: "9E1SLTvngEw",
        videos: [],
        error: false,
        success: false,
        loading: false
    },

    reducers: {
        setUserData: (state, action) => {

        },
        setError: (state, action) => {
            state.error = action.payload
        },

        setSuccess: (state, action) => {
            state.success = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        logoutUser: (state, action) => {
            state.email = ''
            state.nickName = ''
            state.pictureLink = ''
            state.locale = ''
            state.videos = []
            state.verified = ''
            state.targetLanguage = ""
            state.lastVideo = ''

        }

    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            state.email = action.payload.email
            state.nickName = action.payload.nickName
            state.pictureLink = action.payload.pictureLink
            state.locale = action.payload.locale
            state.videos = action.payload.videos
            state.targetLanguage = action.payload.targetLanguage
            state.verified = action.payload.verified
            state.lastVideo = action.payload.lastVideo

        })
            .addCase(setTargetLang.fulfilled, (state, action) => {
                state.targetLanguage = action.payload
                state.loading = false
            })
            .addCase(deleteVideo.fulfilled, (state, action) => {
                let result = state.videos.filter(item => item.vid !== action.payload)
                state.videos = result
                state.loading = false
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false
                if (action.payload.error) {
                    state.error = action.payload.error
                }
                if (action.payload.success) {
                    state.success = action.payload.success
                }
                else if (action.payload.email) {
                    state.email = action.payload.email
                    state.nickName = action.payload.nickName
                    state.pictureLink = action.payload.pictureLink
                    state.locale = action.payload.locale
                    state.videos = action.payload.videos
                    state.targetLanguage = action.payload.targetLanguage
                    state.verified = action.payload.verified
                    state.lastVideo = action.payload.lastVideo

                }
            })
            .addCase(resendVerification.fulfilled, (state, action) => {
                state.loading = false
                if (action.payload.error) {
                    state.error = action.payload.error
                }
                if (action.payload.success) {
                    state.success = action.payload.success
                }
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false
                if (action.payload.error) {
                    state.error = action.payload.error
                }
                if (action.payload.success) {
                    state.success = action.payload.success
                }
                else if (action.payload.email) {
                    state.email = action.payload.email
                    state.nickName = action.payload.nickName
                    state.pictureLink = action.payload.pictureLink
                    state.locale = action.payload.locale
                    state.videos = action.payload.videos
                    state.targetLanguage = action.payload.targetLanguage
                    state.verified = action.payload.verified
                    state.lastVideo = action.payload.lastVideo
                }
            })
            .addCase(changePwd.fulfilled, (state, action) => {
                state.loading = false
                if (action.payload.error) {
                    state.error = action.payload.error
                }
                if (action.payload.success) {
                    state.success = action.payload.success
                }
            })
            .addCase(setLastVideo.fulfilled, (state, action) => {
                state.loading = false
                state.lastVideo = action.payload

            })
    }

})


export { fetchUserData, setTargetLang, deleteVideo, registerUser, resendVerification, loginUser, changePwd, setLastVideo }

export const { setUserData, logoutUser, setError, setSuccess, setLoading } = userSlice.actions

export default userSlice.reducer