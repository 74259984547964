import React from 'react';

const HomePageContent = () => {
    return (
        <div className="mx-auto max-w-4xl px-4 py-8">
            <div className="text-center mb-8">
                <h1 className="text-4xl font-bold mb-4">Welcome to Listen and Recognize</h1>
                <p className="text-lg mb-6">Explore the world of language learning through captivating videos and interactive exercises. Improve your listening skills and expand your vocabulary in a fun and engaging way!</p>
            </div>

            <hr className="border-t border-gray-300 my-8" />

            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Our Approach</h2>
                <p className="mb-4">At Listen and Recognize, we believe in the power of immersion. Our method involves watching videos in foreign languages with subtitles and then completing exercises to reinforce your understanding.</p>
                <p className="mb-4">Whether you're a beginner or an advanced learner, our platform offers a variety of exercises tailored to your level, helping you progress at your own pace.</p>
            </div>

            <hr className="border-t border-gray-300 my-8" />

            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-4">What Our Users Say</h2>
                <div className="mb-4">
                    <p className="italic">"Listen and Recognize has transformed my language learning experience. I now feel more confident in my ability to understand and communicate in different languages."</p>
                    <p>- Mark, 28</p>
                </div>
                <div>
                    <p className="italic">"I've tried many language learning apps before, but Listen and Recognize stands out for its engaging content and effective learning approach."</p>
                    <p>- Sarah, 35</p>
                </div>
            </div>

            <hr className="border-t border-gray-300 my-8" />
        </div>
    );
}

export default HomePageContent;
