import React from 'react'
import { useSelector } from 'react-redux'
import "../PlayerControl/playerControl.css"


function PlaybackSpeed() {

    let player = useSelector(state => state.player.playerRef)

    const radios = [
        {
            name: "0.25",
        },
        {
            name: "0.5",
        },
        {
            name: "0.75",
        },
        {
            name: "1",
        },
    ]

    function setPlaybackSpeed(e) {
        player.current.internalPlayer.setPlaybackRate(parseFloat(e.target.value))
    }

    return (
        (
            <div className="">
                <ul className="flex w-1/3 items-baseline my-1">
                    {
                        radios.map((item, idx) => (
                            <li className='mx-1' key={idx}>
                                <label htmlFor={item.name} className="relative">
                                    <input value={item.name} onClick={setPlaybackSpeed} id={item.name} type="radio" defaultChecked={idx == 3 ? true : false} name="payment" class="sr-only peer" />
                                    <div className="block border-slate-800 w-full p-2 cursor-pointer rounded-lg border bg-white shadow-sm peer-checked:bg-purple-500 duration-200">
                                        <div className="block">
                                            <h3 className=" text-center text-gray-800 font-medium">
                                                {item.name}
                                            </h3>
                                        </div>
                                    </div>

                                </label>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )


        // < div >
        //     <button value={0.25} onClick={setPlaybackSpeed}>0.25</button>
        //     <button value={0.5} onClick={setPlaybackSpeed}>0.5</button>
        //     <button value={0.75} onClick={setPlaybackSpeed}>0.75</button>
        //     <button value={1} onClick={setPlaybackSpeed}>1</button>
        // </ >
    )
}

export default PlaybackSpeed