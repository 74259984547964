import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { setSelectedWordText, setUserWords, setSelectedWordId, setUserTextInSelectedSubtitle } from '../../../../../../features/subtitles/subtitleSlice'
import Menu from "./Menu/Menu"
import style from './Word.modul.css'


function progressProcessor(subtitles) {
    let userTextRateSum = 0
    subtitles.forEach(e => {
        if (e.userText)
            userTextRateSum += window.stringSimilarity.compareTwoStrings(e.text || " ", e.userText || " ")
    });
    return (userTextRateSum / subtitles.length) * 100
}

function Word({ word, index }) {
    const dispatch = useDispatch()
    const { selectedWord, userWords, subtitles } = useSelector(state => state.subtitle)
    const { lastVideo } = useSelector(state => state.userSlice)
    let [menuActive, setMenuActive] = useState(false)


    function handleOnClickWord(e) {
        setMenuActive(!menuActive)
    }


    if (index == selectedWord) {
        return (<input
            autoFocus
            value={word.text.replace(" ", '')}
            onChange={(e) => dispatch(setSelectedWordText(e.target.value))}
            onKeyDown={(e) => {
                dispatch(setUserTextInSelectedSubtitle({ event: e, videoId: lastVideo, progress: progressProcessor(subtitles) }))
                dispatch(setUserWords(e))
            }}
            type='text'
            className=' max-md:p-1 w-1/6 pl-12 pr-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg'
            style={{
                display: 'inline',
                color: `rgb(${255 - (word.similarity * 255)}, ${word.similarity * 255}, 0)`,
            }}>

        </input>)
    }
    else if (word.text) {
        return (<div
            className='rounded-sm text-slate-900 px-2 py-1'
            onClick={handleOnClickWord}
            onDoubleClick={() => dispatch(setSelectedWordId(index))}
            style={{
                position: 'relative',
                boxShadow: `0px 0px 8px rgb(${255 - (word.similarity * 255)}, ${word.similarity * 255}, 0)`,
                margin: "4px",
                border: `2px solid rgb(${255 - (word.similarity * 255)}, ${word.similarity * 255}, 0)`,
                display: 'inline',
                color: "#000000",
            }}>

            {word.text}
            {menuActive ? <Menu index={index} word={word} /> : null}

        </div>
        )
    }
}

export default Word